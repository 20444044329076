font-lg
  font-size $font-size-lg
  line-height 28px
font-md
  font-size $font-size-md
  line-height 26px
font-base
  font-size $font-size-base
  line-height 22px
font-small
  font-size $font-size-sm
  line-height 20px
text-ellipsis
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
border-base($width = 1px, $direction = all)
  if ($direction == bottom)
    border-bottom $width solid $border-color-base
  if ($direction == top)
    border-top $width solid $border-color-base
  if ($direction == vertical)
    border-top $width solid $border-color-base
    border-bottom $width solid $border-color-base
  if ($direction == all)
    border $width solid $border-color-base
flex-center($direction = row)
  display flex
  justify-content center
  align-items center
  flex-direction $direction
flex-row
  display flex
  align-items center
