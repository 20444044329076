.headerWrapper
  z-index 10
  @extend flex-row
  .logoWrapper
    width 200px
    height 100%
    color #fff
    flex-center()
    float left
    width 120px
    height 31px
    margin 16px 24px 16px 0
    background rgba(255, 255, 255, 0.3)
    border-radius 50%
    padding 0 10px
    box-sizing content-box
