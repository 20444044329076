.loginform {
  width: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loginform .login-form-forgot {
  float: right;
}
.loginform .ant-col-rtl .login-form-forgot {
  float: left;
}
.loginform .login-form-button {
  width: 150px;
  /* margin: 0 auto; auto失效，具体长度不失效*/
  margin-left: 50%;
  transform: translateX(-50%);
}