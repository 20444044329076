.cmptContainerWrapper
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  overflow auto
  min-width 1200px
  .mainWrapper
    .siderWrapper
      overflow auto
      height 100%
      border-left none !important
      border-right none !important
      background-color #FFF
    .contentWrapper
      overflow auto
      height 100%
      padding 20px
    .childrenWrapper
      background-color #fff
      height 100%
      padding 20px
      overflow auto